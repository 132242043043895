import Spinner from "react-spinner-material";

import React from "react";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function LoadingAnimation() {
  return (
    <div>
      <span style={style}>
        <Spinner radius={120} color={"red"} stroke={10} visible={true} />
      </span>
    </div>
  );
}

export default LoadingAnimation;
