import React, { lazy, Suspense } from "react";
import "./App.scss";
import requests from "./requests";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";

const Nav = lazy(() => import("./Nav/Nav"));
const Banner = lazy(() => import("./Banner/Banner"));
const Row = lazy(() => import("./Row/Row"));
const Footer = lazy(() => import("./Footer/Footer"));

function App() {
  return (
    <div className="app">
      <Suspense fallback={<LoadingAnimation />}>
        <Nav />
        <Banner fetchUrl={requests.fetchRandom} />
        <Row title="Najnowsze" fetchUrl={requests.fetchLatest} isLargeRow />
        <Row
          title="Odcinki Specjalne"
          fetchUrl={requests.fetchSpecial}
          isLargeRow
        />

        <Footer></Footer>
      </Suspense>
    </div>
  );
}

export default App;
